<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-0">
        <div class="row justify-content-around bg-current px-2">
            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-md d-block text-white"><b>Edit Vark Test</b></h2></div>
        </div>
        <div class="card-body p-2">
            <div class="row" v-if="!isLoad">
                <div class="col-12" v-if="vark != null">
                    <form @submit.prevent="patchVark"  class="row p-2">
                        <div class="col-12 form-group mb-3">
                            <label for="">Title</label>
                            <input type="text" v-model="vark.title" required placeholder="Enter your vark test title" class="form-control text-grey-900 font-xsss fw-600">                        
                        </div>
                        <div class="col-12 form-group mb-3">
                            <label for="">Description</label>
                            <textarea rows="1" v-model="vark.description" required placeholder="Enter your vark test description" class="form-control text-grey-900 font-xsss fw-600"></textarea>
                        </div>
                        <div class="col-12 form-group mb-3">
                            <label for="">Time in Minutes</label>
                            <input type="number" required v-model="vark.time" min="0" placeholder="Enter time minutes" class="form-control text-grey-900 font-xsss fw-600">
                        </div>
                        <div class="col-12 form-group mb-3">
                            <hr>
                        </div>
                        <div class="col-12 form-group text-right my-4">
                            <router-link :to="{name: 'SettingsAdmin', hash: '#VT'}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                            <input type="Submit" class="btn btn-current" value="Submit">
                        </div>
                    </form>
                </div>
                <div v-else class="col-12">
                    <div class="card bg-grey border-0">
                        <div class="card-body text-center py-5">
                            <img :src="'/images/empty.png'" alt="" width="300">
                            <h1 class="text-muted">Data don't exist</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            idVark: this.$route.params.idVark,
            isLoad: true,
            vark: null,
        }
    },
    created() {
        this.getVark()
    },
    methods:{
        async getVark() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/vark/detail?id=${this.idVark}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.vark = res.data.data
                }
                this.isLoad = false
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Vark Test!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async patchVark() {
            var data = {
                _method: 'patch',
                id: this.idVark,
                title: this.vark.title,
                description: this.vark.description,
                time: this.vark.time,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/vark`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Vark Test!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SettingsAdmin', hash: '#VT' })
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Vark Test!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },

    }
}
</script>
